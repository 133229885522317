import dynamic from 'next/dynamic';

import { ILayoutAccount } from '@pra-vendas-themes/interfaces/ILayoutAccount';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const LayoutsAccount = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "LayoutAccountDefault" */ '@pra-vendas-themes/default/components/LayoutAccount'
      )
  ),
};

type TUseLayoutAccountData = {
  LayoutAccount: WithChildren<ILayoutAccount>;
};

export const useLayoutAccountTheme = (
  theme: TThemes
): TUseLayoutAccountData => {
  const LayoutAccount = LayoutsAccount[theme] as WithChildren<ILayoutAccount>;

  return { LayoutAccount };
};
