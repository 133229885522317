import { FC, useState } from 'react';

import { useToast } from 'hooks/useToast';
import { useAuth } from 'hooks/useAuth';

import { signUp } from 'services/api/userService';

import { useModalSignIn } from 'components/Modal/ModalSignIn/hook';
import FormSignUp, { TFormSignUpValues } from 'components/Forms/FormSignUp';

import { Text, ButtonLink } from './styles';

type TModalSignUp = {
  initialValues?: TFormSignUpValues;
  handleClose?: () => void;
};

export const ModalSignUp: FC<TModalSignUp> = ({
  initialValues,
  handleClose,
}) => {
  const { openModal } = useModalSignIn();
  const { openToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { signIn } = useAuth();

  const handleGoToSignIn = (): void => {
    openModal();
  };

  const onSubmit = async (submitValues: TFormSignUpValues): Promise<void> => {
    const { email, password, name } = submitValues;

    try {
      setIsLoading(true);

      await signUp({
        name,
        email,
        password,
      });

      await signIn({
        email,
        password,
      });

      handleClose?.();
      setIsLoading(false);
    } catch (e) {
      if (e.response.data.tag === 'CUSTOMER_EMAIL_ALREADY_EXISTS') {
        openToast('Ops, este email já está cadastrado.');
      } else {
        openToast('Ops, ocorreu um erro inesperado.');
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <FormSignUp
        isLoading={isLoading}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
      <Text>
        Já tem uma conta?{' '}
        <ButtonLink onClick={handleGoToSignIn}>Entrar</ButtonLink>
      </Text>
    </>
  );
};
