/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-danger */
import {
  createContext,
  useContext,
  useEffect,
  useCallback,
  createElement,
  useMemo,
} from 'react';

import Script from 'next/script';
import { useRouter } from 'next/router';
import Head from 'next/head';

import { WithChildren } from 'types/WithChildren';
import { IStoreSettings } from 'types/IStoreSettings';
import { IPromotion } from 'types/IPromotion';
import { IPage } from 'types/IPage';
import { INewsletter } from 'types/INewsletter';
import { ICategory } from 'types/ICategory';
import {
  IAppConfiguration,
  IFacebookPixelSettings,
  IGoogleAdWordsSettings,
  IGoogleAnalyticsSettings,
  IGoogleTagManagerSettings,
  IJivoChatSettings,
} from 'types/IAppConfiguration';

import { StoreSEO } from 'components/StoreSEO';

import { FacebookPixelProvider } from './facebookPixel';

interface ISiteSettingsContextData {
  storeSettings: IStoreSettings;
  categories: ICategory[];
  pages: IPage[];
  newsletterSettings: INewsletter;
  promotions: IPromotion[];
  appConfigurations?: IAppConfiguration[];
  googleAnalyticsSettings?: IGoogleAnalyticsSettings;
  googleAdWordsSettings?: IGoogleAdWordsSettings;
  jivoChatSettings?: IJivoChatSettings;
}

const SiteSettingsContext = createContext<ISiteSettingsContextData>(
  {} as ISiteSettingsContextData
);

const SiteSettingsProvider: WithChildren<ISiteSettingsContextData> = ({
  children,
  storeSettings = {} as IStoreSettings,
  categories = [],
  pages = [],
  newsletterSettings,
  appConfigurations = [],
  promotions = [],
}) => {
  const { pathname } = useRouter();

  const googleAnalyticsSettings = useMemo(() => {
    if (!appConfigurations || appConfigurations.length === 0) return undefined;

    const findGoogleAnalyticsApp = appConfigurations.find(
      (appConfiguration) => appConfiguration.app?.tag === 'google-analytics'
    );

    if (
      !findGoogleAnalyticsApp?.metadata ||
      findGoogleAnalyticsApp.metadata.length === 0
    )
      return undefined;

    return findGoogleAnalyticsApp.metadata.reduce((acc, item) => {
      return { ...acc, [item.name]: item.value };
    }, {}) as IGoogleAnalyticsSettings;
  }, [appConfigurations]);

  const googleAdWordsSettings = useMemo(() => {
    if (!appConfigurations || appConfigurations.length === 0) return undefined;

    const findGoogleAdWordsApp = appConfigurations.find(
      (appConfiguration) => appConfiguration.app?.tag === 'google-adwords'
    );

    if (
      !findGoogleAdWordsApp?.metadata ||
      findGoogleAdWordsApp.metadata.length === 0
    )
      return undefined;

    return findGoogleAdWordsApp.metadata.reduce((acc, item) => {
      return { ...acc, [item.name]: item.value };
    }, {}) as IGoogleAdWordsSettings;
  }, [appConfigurations]);

  const googleTagManagerSettings = useMemo(() => {
    if (!appConfigurations || appConfigurations.length === 0) return undefined;

    const findGoogleTagManagerApp = appConfigurations.find(
      (appConfiguration) => appConfiguration.app?.tag === 'google-tag-manager'
    );

    if (
      !findGoogleTagManagerApp?.metadata ||
      findGoogleTagManagerApp.metadata.length === 0
    )
      return undefined;

    return findGoogleTagManagerApp.metadata.reduce((acc, item) => {
      return { ...acc, [item.name]: item.value };
    }, {}) as IGoogleTagManagerSettings;
  }, [appConfigurations]);

  const jivoChatSettings = useMemo(() => {
    if (!appConfigurations || appConfigurations.length === 0) return undefined;

    const findJivoChatApp = appConfigurations.find(
      (appConfiguration) => appConfiguration.app?.tag === 'jivo-chat'
    );

    if (!findJivoChatApp?.metadata || findJivoChatApp.metadata.length === 0)
      return undefined;

    return findJivoChatApp.metadata.reduce((acc, item) => {
      return { ...acc, [item.name]: item.value };
    }, {}) as IJivoChatSettings;
  }, [appConfigurations]);

  const facebookPixelSettings = useMemo(() => {
    if (!appConfigurations || appConfigurations.length === 0) return undefined;

    const findFacebookPixelApp = appConfigurations.find(
      (appConfiguration) => appConfiguration.app?.tag === 'facebook-pixel'
    );

    if (
      !findFacebookPixelApp?.metadata ||
      findFacebookPixelApp.metadata.length === 0
    )
      return undefined;

    return findFacebookPixelApp.metadata.reduce((acc, item) => {
      return { ...acc, [item.name]: item.value };
    }, {}) as IFacebookPixelSettings;
  }, [appConfigurations]);

  const GoogleTagManagerScript = useMemo(() => {
    if (!googleTagManagerSettings?.['container-id']) return null;

    return (
      <Script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${googleTagManagerSettings[
            'container-id'
          ].trim()}');`,
        }}
      />
    );
  }, [googleTagManagerSettings]);

  const GtagScript = useMemo(() => {
    if (
      !(
        googleAnalyticsSettings?.['tracking-id'] ||
        (googleAdWordsSettings?.['conversion-id'] &&
          googleAdWordsSettings?.['conversion-label'])
      )
    )
      return null;

    let id = '';

    if (googleAnalyticsSettings?.['tracking-id']) {
      id = googleAnalyticsSettings['tracking-id'];
    } else if (
      googleAdWordsSettings?.['conversion-id'] &&
      googleAdWordsSettings?.['conversion-label']
    ) {
      id = googleAdWordsSettings?.['conversion-id']!.includes('AW-')
        ? googleAdWordsSettings?.['conversion-id']
        : `AW-${googleAdWordsSettings?.['conversion-label']}`;
    }

    return (
      <>
        <Script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
        />

        <Script
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${id}');
        `,
          }}
        />
      </>
    );
  }, [googleAdWordsSettings, googleAnalyticsSettings]);

  const renderJivoChat = useCallback(() => {
    if (typeof window === 'undefined' || !jivoChatSettings?.code) return null;

    const frag = document
      .createRange()
      .createContextualFragment(jivoChatSettings.code);

    if (!frag.firstElementChild) return null;

    return createElement('script', {
      async: true,
      src: frag.firstElementChild.getAttribute('src'),
    });
  }, [jivoChatSettings]);

  useEffect(() => {
    if (!googleTagManagerSettings?.['container-id']) return;

    const body = document.getElementsByTagName('body')?.[0];

    if (body) {
      const noscript = document.createElement('noscript');

      const iframe = document.createElement('iframe');
      iframe.setAttribute(
        'src',
        `https://www.googletagmanager.com/ns.html?id=${googleTagManagerSettings['container-id']}`
      );
      iframe.setAttribute('height', '0');
      iframe.setAttribute('width', '0');
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';
      noscript.appendChild(iframe);

      body.insertBefore(noscript, body.children[0]);
    }
  }, [googleTagManagerSettings]);

  return (
    <SiteSettingsContext.Provider
      value={{
        storeSettings,
        categories,
        pages,
        newsletterSettings,
        promotions,
        googleAnalyticsSettings,
        googleAdWordsSettings,
        jivoChatSettings,
      }}
    >
      <Head>
        {GoogleTagManagerScript}

        {GtagScript}

        {renderJivoChat()}
      </Head>

      <FacebookPixelProvider settings={facebookPixelSettings}>
        {!pathname.includes('/produtos/') && <StoreSEO />}

        {children}
      </FacebookPixelProvider>
    </SiteSettingsContext.Provider>
  );
};

function useSiteSettings(): ISiteSettingsContextData {
  return useContext(SiteSettingsContext);
}

export { SiteSettingsProvider, useSiteSettings };
