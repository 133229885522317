import dynamic from 'next/dynamic';

import { IForm } from '@pra-vendas-themes/interfaces/IForm';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const Forms = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "FormDefault" */ '@pra-vendas-themes/default/components/Form'
      )
  ),
};

type TUseBodyData = {
  Form: WithChildren<IForm>;
};

export const useFormTheme = (theme: TThemes): TUseBodyData => {
  const Form = Forms[theme] as WithChildren<IForm>;

  return { Form };
};
