import { IForm } from '@pra-vendas-themes/interfaces/IForm';

import { useComponentsTheme } from 'hooks/useComponentsTheme';

import { WithChildren } from 'types/WithChildren';

export const Form: WithChildren<IForm> = ({ children, ...props }) => {
  const { Form: FormTheme } = useComponentsTheme();

  return <FormTheme {...props}>{children}</FormTheme>;
};
