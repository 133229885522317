import dynamic from 'next/dynamic';

import { IBody } from '@pra-vendas-themes/interfaces/IBody';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const Bodys = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "BodyDefault" */ '@pra-vendas-themes/default/components/Body'
      )
  ),
};

type TUseBodyData = {
  Body: WithChildren<IBody>;
};

export const useBodyTheme = (theme: TThemes): TUseBodyData => {
  const Body = Bodys[theme] as WithChildren<IBody>;

  return { Body };
};
