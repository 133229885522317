import type { FC } from 'react';

import { IFooter } from '@pra-vendas-themes/interfaces/IFooter';

import { useToast } from 'hooks/useToast';
import { useSiteSettings } from 'hooks/useSiteSettings';
import { useComponentsTheme } from 'hooks/useComponentsTheme';
import { useAuth } from 'hooks/useAuth';

import { subscribeNewsletter } from 'services/api/newsletterService';

export const Footer: FC<IFooter> = () => {
  const { Footer: FooterTheme } = useComponentsTheme();
  const { customer } = useAuth();
  const { openToast } = useToast();
  const { storeSettings, pages, newsletterSettings } = useSiteSettings();
  const { socialNetworks, address, contact } = storeSettings || {};

  async function handleSendNewsletter(email: string): Promise<void> {
    try {
      await subscribeNewsletter({
        newsletter: {
          email,
          name: customer?.name,
          phoneNumber: customer?.phoneNumber,
        },
      });

      openToast(
        newsletterSettings?.successMessage ||
          'Boa, seu e-mail foi cadastrado com sucesso e agora você está em nossa lista!',
        {
          color: 'success',
        }
      );
    } catch (e) {
      if (e.response.data.tag === 'NEWSLETTER_SUBSCRPTION_ALREADY_EXISTS') {
        openToast('Ops, esse e-mail já está cadastrado em nossa newsletter!');
      } else {
        openToast('Ops, houve um erro inesperado!');
      }
    }
  }

  return (
    <FooterTheme
      createdByLogo="/pravendas-logo.png"
      contact={
        (address || contact) && {
          ...(address && { address }),
          ...contact,
        }
      }
      pages={pages?.length ? pages : undefined}
      socialMedias={socialNetworks}
      newsletter={{
        ...(newsletterSettings?.title && { title: newsletterSettings?.title }),
        ...(newsletterSettings?.description && {
          description: newsletterSettings?.description,
        }),
        ...(newsletterSettings?.placeholder && {
          placeholder: newsletterSettings?.placeholder,
        }),
        ...(newsletterSettings?.status === 'active' && {
          onSend: handleSendNewsletter,
        }),
      }}
    />
  );
};
