/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { api } from 'services/api';

import type { IPage } from 'types/IPage';
import { IHostnameParam } from 'types/IHostnameParam';

export async function getPages(config: IHostnameParam): Promise<IPage[]> {
  try {
    const { hostname } = config || {};

    const response = await api.get<IPage[]>('/pages', {
      params: {
        status: 'active',
      },
      ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
    });

    return response.data;
  } catch (error) {
    console.error('[getPages]', error.message);
    return [];
  }
}
