import axios from 'axios';
import { env } from 'constants/env';

export const api = axios.create({
  baseURL: env?.API_URL,
});

export function addBearerToken(token: string): void {
  api.defaults.headers.Authorization = `Bearer ${token}`;
}
