import { createContext, useContext } from 'react';

import { IToast } from '@pra-vendas-themes/interfaces/IToast';
import { ITitleWithSort } from '@pra-vendas-themes/interfaces/ITitleWithSort';
import { IProductsList } from '@pra-vendas-themes/interfaces/IProductsList';
import { IModal } from '@pra-vendas-themes/interfaces/IModal';
import { ILayoutAccount } from '@pra-vendas-themes/interfaces/ILayoutAccount';
import { ILayout } from '@pra-vendas-themes/interfaces/ILayout';
import { IHeader } from '@pra-vendas-themes/interfaces/IHeader';
import { IFormField } from '@pra-vendas-themes/interfaces/IFormField';
import { IForm } from '@pra-vendas-themes/interfaces/IForm';
import { IFooter } from '@pra-vendas-themes/interfaces/IFooter';
import { ICookies } from '@pra-vendas-themes/interfaces/ICookies';
import { ICartDetail } from '@pra-vendas-themes/interfaces/ICartDetail';
import { IButton } from '@pra-vendas-themes/interfaces/IButton';
import { IBody } from '@pra-vendas-themes/interfaces/IBody';
import { IBag } from '@pra-vendas-themes/interfaces/IBag';
import { IAlert } from '@pra-vendas-themes/interfaces/IAlert';

import { useSiteSettings } from 'hooks/useSiteSettings';
import { useToastTheme } from 'hooks/components/useToastTheme';
import { useTitleWithSortTheme } from 'hooks/components/useTitleWithSortTheme';
import { useProductsListTheme } from 'hooks/components/useProductsListTheme';
import { useModalTheme } from 'hooks/components/useModalTheme';
import { useLayoutTheme } from 'hooks/components/useLayoutTheme';
import { useLayoutAccountTheme } from 'hooks/components/useLayoutAccountTheme';
import { useHeaderTheme } from 'hooks/components/useHeaderTheme';
import { useFormTheme } from 'hooks/components/useFormTheme';
import { useFormFieldTheme } from 'hooks/components/useFormFieldTheme';
import { useFooterTheme } from 'hooks/components/useFooterTheme';
import { useCartDetailTheme } from 'hooks/components/useCartDetailTheme';
import { useButtonTheme } from 'hooks/components/useButtonTheme';
import { useBodyTheme } from 'hooks/components/useBodyTheme';
import { useBagTheme } from 'hooks/components/useBagTheme';
import { useAlertTheme } from 'hooks/components/useAlertTheme';

import { WithChildren } from 'types/WithChildren';

import { useCookiesTheme } from './components/useCookiesTheme';

interface IComponentsThemeContextData {
  Layout: WithChildren<ILayout>;
  LayoutAccount: WithChildren<ILayoutAccount>;
  Header: WithChildren<IHeader>;
  Footer: WithChildren<IFooter>;
  Body: WithChildren<IBody>;
  FormField: WithChildren<IFormField>;
  Form: WithChildren<IForm>;
  Button: WithChildren<IButton>;
  Alert: WithChildren<IAlert>;
  Modal: WithChildren<IModal>;
  Toast: WithChildren<IToast>;
  Bag: WithChildren<IBag>;
  ProductsList: WithChildren<IProductsList>;
  TitleWithSort: WithChildren<ITitleWithSort>;
  CartDetail: WithChildren<ICartDetail>;
  Cookies: WithChildren<ICookies>;
}

const ComponentsThemeContext = createContext<IComponentsThemeContextData>(
  {} as IComponentsThemeContextData
);

const ComponentsThemeProvider: WithChildren = ({ children }) => {
  const { storeSettings } = useSiteSettings();
  const { theme } = storeSettings || {};

  const tag = theme?.tag || 'default';

  const { Layout } = useLayoutTheme(tag);
  const { LayoutAccount } = useLayoutAccountTheme(tag);
  const { Body } = useBodyTheme(tag);
  const { Header } = useHeaderTheme(tag);
  const { Footer } = useFooterTheme(tag);
  const { FormField } = useFormFieldTheme(tag);
  const { Form } = useFormTheme(tag);
  const { Button } = useButtonTheme(tag);
  const { Alert } = useAlertTheme(tag);
  const { Modal } = useModalTheme(tag);
  const { Toast } = useToastTheme(tag);
  const { Bag } = useBagTheme(tag);
  const { ProductsList } = useProductsListTheme(tag);
  const { TitleWithSort } = useTitleWithSortTheme(tag);
  const { CartDetail } = useCartDetailTheme(tag);
  const { Cookies } = useCookiesTheme(tag);

  return (
    <ComponentsThemeContext.Provider
      value={{
        Layout,
        LayoutAccount,
        Header,
        Footer,
        Body,
        FormField,
        Form,
        Button,
        Alert,
        Modal,
        Toast,
        Bag,
        ProductsList,
        TitleWithSort,
        CartDetail,
        Cookies,
      }}
    >
      {children}
    </ComponentsThemeContext.Provider>
  );
};

function useComponentsTheme(): IComponentsThemeContextData {
  return useContext(ComponentsThemeContext);
}

export { ComponentsThemeProvider, useComponentsTheme };
