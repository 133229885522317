/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-nested-ternary */
import {
  FC,
  useState,
  ChangeEventHandler,
  useCallback,
  FormEventHandler,
  useEffect,
} from 'react';

import { useToast } from 'hooks/useToast';

import { validateEmail } from 'utils/validateEmail';

import { api } from 'services/api';

import { FormField } from 'components/Form/FormField';
import { Form } from 'components/Form';
import { Button } from 'components/Button';

import { Text, ButtonLink } from './styles';

import { useModalSignIn } from '../ModalSignIn/hook';

type TModalForgotPassword = {
  handleClose?: () => void;
};

interface IFormData {
  email: string;
  resetCode: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

interface IFormErrors {
  email: string;
  resetCode: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

const VERIFICATION_SECONDS = 60;
let countdownTimeout: NodeJS.Timeout;

export const ModalForgotPassword: FC<TModalForgotPassword> = ({
  handleClose = () => null,
}) => {
  const { openModal } = useModalSignIn();
  const { openToast } = useToast();

  const [seconds, setSeconds] = useState(VERIFICATION_SECONDS);
  const [isCountdownActive, setIsCountdownActive] = useState(false);

  const [hasAlreadySentResetCode, setHasAlreadySentResetCode] = useState(false);
  const [hasAlreadyValidatedResetCode, setHasAlreadyValidatedResetCode] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState<IFormData>({
    email: '',
    newPassword: '',
    newPasswordConfirmation: '',
    resetCode: '',
  });

  const [formErrors, setFormErrors] = useState({} as IFormErrors);

  useEffect(() => {
    if (isCountdownActive && seconds > 0) {
      countdownTimeout = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    } else if (isCountdownActive && seconds === 0) {
      setIsCountdownActive(false);
      setSeconds(VERIFICATION_SECONDS);
      clearTimeout(countdownTimeout);
    }
  }, [isCountdownActive, seconds]);

  const handleGoToSignIn = (): void => {
    openModal();
  };

  const handleChangeValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value } = event.target;

      setFormData((prevState) => ({
        ...prevState,
        [name]: name === 'resetCode' ? value.toUpperCase() : value,
      }));

      // @ts-ignore
      if (formErrors[name]) {
        setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
      }
    },
    [formErrors]
  );

  const handleForgotPassword = async (): Promise<void> => {
    try {
      if (!formData.email) {
        setFormErrors((prevState) => ({
          ...prevState,
          email: 'E-mail obrigatório',
        }));
        setIsLoading(false);
        return;
      }

      const isEmailValid = validateEmail(formData.email);

      if (!isEmailValid) {
        setFormErrors((prevState) => ({
          ...prevState,
          email: 'E-mail inválido',
        }));
        setIsLoading(false);
        return;
      }

      await api.post('/customers/forgot-password', {
        value: formData.email,
        target: 'email',
      });

      setHasAlreadySentResetCode(true);
      setIsCountdownActive(true);
    } catch (error) {
      setIsLoading(false);
      openToast('Ops, ocorreu um erro ao enviar o código de verificação!', {
        color: 'danger',
      });
    }
  };

  const handleValidateResetToken = useCallback(async (): Promise<void> => {
    try {
      if (!formData.resetCode) {
        setFormErrors((prevState) => ({
          ...prevState,
          resetCode: 'Código de recuperação obrigatório',
        }));
        setIsLoading(false);
        return;
      }

      if (formData.resetCode.length < 5) {
        setFormErrors((prevState) => ({
          ...prevState,
          resetCode: 'Código de recuperação inválido',
        }));
        setIsLoading(false);
        return;
      }

      await api.post('/customers/validate-reset-code', {
        email: formData.email,
        code: formData.resetCode,
      });

      setHasAlreadyValidatedResetCode(true);
    } catch (error: any) {
      setIsLoading(false);

      const errorTag = error?.response?.data?.tag;

      if (errorTag === 'RESET_CODE_INVALID') {
        setFormErrors((prevState) => ({
          ...prevState,
          resetCode: 'Código inválido',
        }));
        return;
      }

      openToast(
        'Ops, ocorreu um erro ao validar o seu código de recuperação!',
        { color: 'danger' }
      );
    }
  }, [formData.email, formData.resetCode, openToast]);

  const handleResetPassword = async (): Promise<void> => {
    try {
      if (!formData.newPassword || !formData.newPasswordConfirmation) {
        setFormErrors((prevState) => ({
          ...prevState,
          ...(!formData.newPassword && {
            newPassword: 'Nova senha obrigatória',
          }),
          ...(!formData.newPasswordConfirmation && {
            newPasswordConfirmation: 'Confirmação de senha obrigatória',
          }),
        }));
        setIsLoading(false);
        return;
      }

      if (formData.newPassword !== formData.newPasswordConfirmation) {
        setFormErrors((prevState) => ({
          ...prevState,
          newPasswordConfirmation: 'As senhas precisam ser iguais',
        }));
        setIsLoading(false);
        return;
      }

      await api.post('/customers/reset-password', {
        email: formData.email,
        code: formData.resetCode,
        password: formData.newPassword,
      });

      openToast('Boa, senha alterada com sucesso!', { color: 'success' });

      handleClose();
    } catch (error) {
      setIsLoading(false);

      openToast('Ops, ocorreu um erro ao alterar sua senha!', {
        color: 'danger',
      });
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    if (hasAlreadySentResetCode && !hasAlreadyValidatedResetCode) {
      await handleValidateResetToken();
    } else if (hasAlreadyValidatedResetCode) {
      await handleResetPassword();
    } else {
      await handleForgotPassword();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (formData.resetCode.length === 6 && !hasAlreadyValidatedResetCode) {
        await handleValidateResetToken();
      }
    })();
  }, [
    hasAlreadyValidatedResetCode,
    handleValidateResetToken,
    formData.resetCode.length,
  ]);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        renderActions={() => (
          <Button
            isLoading={isLoading}
            width={1}
            font="small"
            lineHeight="medium"
            type="submit"
          >
            RECUPERAR
          </Button>
        )}
      >
        {hasAlreadySentResetCode && !hasAlreadyValidatedResetCode ? (
          <FormField
            name="resetCode"
            label="Código de recuperação"
            maxLength={6}
            autocomplete="one-time-code"
            placeholder="Digite o código de recuperação"
            onChange={handleChangeValue}
            error={formErrors.resetCode}
            value={formData.resetCode}
          />
        ) : hasAlreadyValidatedResetCode ? (
          <>
            <FormField
              name="newPassword"
              type="password"
              label="Nova senha"
              placeholder="Digite a nova senha"
              onChange={handleChangeValue}
              error={formErrors.newPassword}
              value={formData.newPassword}
            />

            <FormField
              name="newPasswordConfirmation"
              type="password"
              label="Confirmação da nova senha"
              placeholder="Digite a nova senha novamente"
              onChange={handleChangeValue}
              error={formErrors.newPasswordConfirmation}
              value={formData.newPasswordConfirmation}
            />
          </>
        ) : (
          <FormField
            name="email"
            label="E-mail"
            placeholder="Digite o seu e-mail"
            onChange={handleChangeValue}
            error={formErrors.email}
            value={formData.email}
          />
        )}
      </Form>

      {hasAlreadySentResetCode &&
        !hasAlreadyValidatedResetCode &&
        isCountdownActive && (
          <Text>
            Reenvie o código em <strong>{seconds}</strong> segundos
          </Text>
        )}

      {hasAlreadySentResetCode &&
        !hasAlreadyValidatedResetCode &&
        !isCountdownActive && (
          <Text>
            Não recebeu o código?{' '}
            <ButtonLink onClick={handleForgotPassword}>Reenviar</ButtonLink>
          </Text>
        )}

      <Text>
        Já tem uma conta?{' '}
        <ButtonLink onClick={handleGoToSignIn}>Voltar</ButtonLink>
      </Text>
    </>
  );
};
