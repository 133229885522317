import qs from 'qs';
import { IProduct } from 'types/IProduct';

import { replaceSpecialCharacters } from 'utils/replaceSpecialCharacteres';

export const getProductLink = (product?: IProduct): string => {
  if (!product) return '';

  if (product.relatedWith?.tag) {
    const attributes: Record<string, string> = {};
    product.attributes?.forEach((attribute) => {
      attributes[replaceSpecialCharacters(attribute.name)] =
        replaceSpecialCharacters(attribute.value?.name || '');
    });

    return `/produtos/${product.relatedWith?.tag}?${qs.stringify(attributes)}`;
  }
  return `/produtos/${product.tag}`;
};
