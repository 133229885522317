import dynamic from 'next/dynamic';

import { ICookies } from '@pra-vendas-themes/interfaces/ICookies';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const CookiesT = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "CookiesDefault" */ '@pra-vendas-themes/default/components/Cookies'
      )
  ),
};

type TUseCookiesData = {
  Cookies: WithChildren<ICookies>;
};

export const useCookiesTheme = (theme: TThemes): TUseCookiesData => {
  const Cookies = CookiesT[theme] as WithChildren<ICookies>;

  return { Cookies };
};
