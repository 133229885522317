import { transparentize } from 'polished';
import styled from 'styled-components';

export const Text = styled.div`
  text-align: center;
  font-size: ${(p) => p.theme.font.small};
  margin-top: ${(p) => p.theme.spacing.medium};
`;

export const ButtonLink = styled.button`
  cursor: pointer;
  font-weight: bold;
  color: ${(p) => p.theme.colors.primary};
  font-size: ${(p) => p.theme.font.small};

  transition: color 0.3s;
  will-change: color;

  &:hover {
    color: ${(p) => transparentize(0.15, p.theme.colors.primary)};
  }
`;
