import dynamic from 'next/dynamic';

import { IBag } from '@pra-vendas-themes/interfaces/IBag';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const Bags = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "BagDefault" */ '@pra-vendas-themes/default/components/Bag'
      )
  ),
};

type TUseBagData = {
  Bag: WithChildren<IBag>;
};

export const useBagTheme = (theme: TThemes): TUseBagData => {
  const Bag = Bags[theme] as WithChildren<IBag>;

  return { Bag };
};
