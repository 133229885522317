import { IHostnameParam } from 'types/IHostnameParam';
import { IShippingOption } from 'types/IShipping';

import { api } from 'services/api/index';

export type TShippingCalculateParams = IHostnameParam & {
  toPostalCode: string;
  cart: string;
};

export async function shippingCalculate(
  config: TShippingCalculateParams
): Promise<IShippingOption[]> {
  const { hostname, cart, toPostalCode } = config;

  const response = await api.post(
    `/shipping/calculate`,
    {
      cart,
      toPostalCode,
    },
    {
      ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
    }
  );

  return response.data;
}
