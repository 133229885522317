import styled from 'styled-components';

interface WrapperProps {
  hasJivoChat: boolean;
}

export const Wrapper = styled.a<WrapperProps>`
  cursor: pointer;
  width: 56px;
  height: 56px;
  background-color: #25d366;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.theme.font.xxLarge};
  color: #fff;
  z-index: 20;
  position: fixed;
  right: ${(p) => p.theme.spacing.xLarge};
  bottom: ${(p) =>
    p.hasJivoChat
      ? `calc(40px + ${p.theme.spacing.xLarge})`
      : p.theme.spacing.xLarge};

  transition: background-color 0.3s;

  &:hover {
    background-color: #19a850;
  }

  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
    font-size: ${(p) => p.theme.font.xLarge};

    bottom: ${(p) =>
      p.hasJivoChat
        ? `calc(48px + ${p.theme.spacing.large} + ${p.theme.spacing.large})`
        : p.theme.spacing.large};
    right: ${(p) => p.theme.spacing.large};
  }
`;
