import { IHostnameParam } from 'types/IHostnameParam';

import { ICustomer } from 'hooks/useAuth';

import { api } from 'services/api';

export type TUpdateCustomerParams = IHostnameParam & {
  name?: string;
  email?: string;
  document?: string;
  phoneNumber?: string;
  password?: string;
  addresses?: {
    postalCode: string;
    street: string;
    number: string;
    neighborhood: string;
    complement: string;
    city: string;
    state: string;
  }[];
};

export async function updateCustomer(
  id: string,
  config: TUpdateCustomerParams
): Promise<ICustomer> {
  const { hostname, name, email, document, phoneNumber, password, addresses } =
    config;

  const response = await api.put(
    `/customers/${id}`,
    {
      name,
      email,
      document: document?.replace(/\D/g, ''),
      phoneNumber: phoneNumber?.replace(/\D/g, ''),
      password,
      addresses,
    },
    {
      ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
    }
  );

  return response.data;
}

export async function getCustomerById(
  id: string,
  config?: IHostnameParam
): Promise<ICustomer> {
  const { hostname } = config || {};

  const response = await api.get(`/customers/${id}`, {
    ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
  });

  return response.data;
}

export async function getCustomerByEmail(
  email: string,
  config?: IHostnameParam
): Promise<ICustomer> {
  const { hostname } = config || {};

  const response = await api.get(`/customers/email/${email}`, {
    ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
  });

  return response.data;
}

export async function getCustomerByDocument(
  document: string,
  config?: IHostnameParam
): Promise<ICustomer> {
  const { hostname } = config || {};

  const response = await api.get(`/customers/document/${document}`, {
    ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
  });

  return response.data;
}

export async function getCustomerByPhoneNumber(
  phoneNumber: string,
  config?: IHostnameParam
): Promise<ICustomer> {
  const { hostname } = config || {};

  const response = await api.get(`/customers/phone-number/${phoneNumber}`, {
    ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
  });

  return response.data;
}
