import { api } from 'services/api/index';

import { IHostnameParam } from 'types/IHostnameParam';
import { ICart, ICartItem } from 'types/ICart';

export type TCreateCartParams = IHostnameParam & {
  items: ICartItem[];
  _id: string;
};

export async function createCart(config: TCreateCartParams): Promise<ICart> {
  const { hostname, items, _id } = config;

  const response = await api.post(
    `/carts`,
    {
      items,
      _id,
    },
    {
      ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
    }
  );

  return response.data;
}

export type TAddProductInCartParams = IHostnameParam & {
  items: ICartItem[];
};

export async function addProductInCart(
  id: string,
  config: TAddProductInCartParams
): Promise<ICart> {
  const { hostname, items } = config;

  const response = await api.put(
    `/carts/${id}`,
    {
      items,
    },
    {
      ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
    }
  );

  return response.data;
}

// export type TAddCustomerInCartParams = IHostnameParam & {
//   customer: string;
// };
//
// export async function addCustomerInCart(
//   id: string,
//   config: TAddCustomerInCartParams
// ): Promise<ICart> {
//   const { hostname, customer } = config;
//
//   const response = await api.put(
//     `/carts/${id}`,
//     {
//       customer,
//     },
//     {
//       ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
//     }
//   );
//
//   return response.data;
// }
//
export type TGetCartByIdParams = IHostnameParam & {
  id: string;
};

export async function getCartById(config: TGetCartByIdParams): Promise<ICart> {
  const { hostname, id } = config;

  const response = await api.get(`/carts/${id}`, {
    ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
  });

  return response.data;
}
