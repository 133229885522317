import dynamic from 'next/dynamic';

import { IButton } from '@pra-vendas-themes/interfaces/IButton';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const Buttons = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "ButtonDefault" */ '@pra-vendas-themes/default/components/Button'
      )
  ),
};

type TUseButtonData = {
  Button: WithChildren<IButton>;
};

export const useButtonTheme = (theme: TThemes): TUseButtonData => {
  const Button = Buttons[theme] as WithChildren<IButton>;

  return { Button };
};
