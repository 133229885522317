import { IButton } from '@pra-vendas-themes/interfaces/IButton';

import { useComponentsTheme } from 'hooks/useComponentsTheme';

import { WithChildren } from 'types/WithChildren';

export const Button: WithChildren<IButton> = ({ children, ...props }) => {
  const { Button: ButtonTheme } = useComponentsTheme();

  return <ButtonTheme {...props}>{children}</ButtonTheme>;
};
