import { env } from 'constants/env';
import { IHostnameParam } from 'types/IHostnameParam';
import { IAddress } from 'types/IStoreSettings';

import { ICustomer } from 'hooks/useAuth';

import { api } from 'services/api';

export type TSignInParams = IHostnameParam & {
  email: string;
  password: string;
};

export async function signIn(config: TSignInParams): Promise<any> {
  const { hostname, email, password } = config;

  const response = await api.post(
    `/customer-auth/signin`,
    {
      email,
      password,
    },
    {
      ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
    }
  );

  return response.data;
}

export type TSignUpParams = IHostnameParam & {
  name: string;
  email: string;
  password: string;
  document?: string;
  phoneNumber?: string;
  addresses?: IAddress[];
};

export async function signUp(config: TSignUpParams): Promise<any> {
  const { hostname, ...rest } = config;

  const response = await api.post(`/customer-auth/signup`, rest, {
    ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
  });

  return response.data;
}

export type TGetCustomerByEmailParams = IHostnameParam & {
  email: string;
};

export async function getCustomerByEmail(
  config: TGetCustomerByEmailParams
): Promise<ICustomer> {
  const { hostname, email } = config;

  const response = await api.get(
    `/customers/email/${email}`,

    {
      ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
    }
  );

  return response.data;
}
