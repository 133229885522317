/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { api } from 'services/api';

import type { IStoreSettings } from 'types/IStoreSettings';
import { IHostnameParam } from 'types/IHostnameParam';

export async function getStoreSettings(
  config: IHostnameParam
): Promise<IStoreSettings> {
  try {
    const { hostname } = config || {};

    const response = await api.get<IStoreSettings>(
      `/stores/domain/${hostname}`
    );

    return response.data;
  } catch (error) {
    console.error('[getStoreSettings]', error.message);
    throw error;
  }
}
