/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';

import { useRouter } from 'next/router';

import * as uuid from 'uuid';

import { useToast } from 'hooks/useToast';
import { useAuth } from 'hooks/useAuth';

import { getProductLink } from 'utils/getProductLink';

import { shippingCalculate } from 'services/api/shippingService';
import { getCouponByName } from 'services/api/couponService';
import {
  createCart as createCartService,
  addProductInCart as addProductInCartService,
  getCartById,
} from 'services/api/cartService';

import { WithChildren } from 'types/WithChildren';
import { TPaymentMethod } from 'types/TPaymentMethod';
import { IShippingOption } from 'types/IShipping';
import { IProduct } from 'types/IProduct';
import { ICart, ICartItem } from 'types/ICart';

import { Bag } from 'components/Bag';

import { useSiteSettings } from './useSiteSettings';
import { useFacebookPixel } from './facebookPixel';

interface IBagContextData {
  openBag: () => void;
  closeBag: () => void;
  clearShipping: () => void;
  addProductInCart: (product: IProduct, quantity?: number) => void;
  itemsQuantity: number;
  cart?: ICart;
  addOneItem: (item: ICartItem | IProduct) => void;
  removeOneItem: (item: ICartItem | IProduct) => void;
  removeItem: (item: ICartItem) => void;
  calculateShipping: (toPostalCode: string) => Promise<void>;
  selectShipping: (shipping: IShippingOption) => void;
  shippingOptions?: IShippingOption[];
  shippingSelected?: IShippingOption;
  postalCode?: string;
  selectPaymentMethod(paymentMethod: TPaymentMethod): void;
  selectedPaymentMethod: TPaymentMethod | null;
  coupon?: any;
  isAplyingCoupon: boolean;
  isCalculatingShipping: boolean;

  applyCoupon: (couponName: string) => Promise<void>;

  clearCart: () => void;

  onClickItem: (product: IProduct) => void;
  discount: number;
}

const BagContext = createContext<IBagContextData>({} as IBagContextData);

const BagProvider: WithChildren = ({ children }) => {
  const router = useRouter();
  const { track } = useFacebookPixel();
  const { openToast } = useToast();
  const { customer } = useAuth();
  const { storeSettings, promotions, googleAnalyticsSettings } =
    useSiteSettings();

  const [cart, setCart] = useState<ICart>();
  const [isOpen, setIsOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [shippingOptions, setShippingOptions] = useState<IShippingOption[]>();
  const [shippingSelected, setShippingSelected] = useState<IShippingOption>();
  const [postalCode, setPostalCode] = useState<string>();
  const [coupon, setCoupon] = useState<any>();
  const [isAplyingCoupon, setIsAplyingCoupon] = useState(false);
  const [isCalculatingShipping, setIsCalculatingShipping] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<TPaymentMethod | null>(null);

  useEffect(() => {
    const customerPostalCode = customer?.addresses?.[0]?.postalCode;
    if (customerPostalCode) {
      setPostalCode(customerPostalCode);
    }
  }, [customer]);

  useEffect(() => {
    async function getCart(): Promise<void> {
      if (typeof window === 'undefined') return;

      const storagedCart: ICart = JSON.parse(
        localStorage.getItem('@PraVendas:cart') || '{}'
      );

      try {
        if (storagedCart?._id) {
          const fetchedCart = await getCartById({ id: storagedCart._id });

          if (fetchedCart) {
            setCart(fetchedCart);
          }
        }
      } catch (e) {
        localStorage.removeItem('@PraVendas:cart');
      }
    }

    getCart();
  }, []);

  const itemsQuantity = useMemo(() => {
    if (!cart) return 0;

    return cart.items.reduce((current, next) => {
      return current + next.quantity;
    }, 0);
  }, [cart]);

  const promotionDiscount = useMemo(() => {
    if (!promotions || promotions.length === 0) return 0;

    const allDiscountPromotions = promotions.filter(
      (promotion) =>
        !!selectedPaymentMethod &&
        promotion.type === 'discount' &&
        (promotion.discountValue || 0) >= 0 &&
        (promotion.minimumValue || 0) <= (cart?.total || 0) &&
        promotion.paymentMethods?.includes(selectedPaymentMethod)
    );

    if (!allDiscountPromotions || allDiscountPromotions.length === 0) return 0;

    const discounts = allDiscountPromotions.reduce((acc, promotion) => {
      let discountValue = 0;

      if (promotion.discountType === 'absolute') {
        discountValue = promotion.discountValue!;
      } else {
        discountValue = (promotion.discountValue! * (cart?.total || 0)) / 100;
      }

      return [...acc, Number(discountValue.toFixed(2))];
    }, [] as number[]);

    return Math.max(...discounts);
  }, [cart?.total, promotions, selectedPaymentMethod]);

  const couponDiscount = useMemo(() => {
    if (!coupon || !coupon.discount) return 0;

    if (coupon.discountType === 'value') {
      return coupon.discount;
    }

    return ((cart?.total || 0) * coupon.discount) / 100;
  }, [coupon, cart]);

  const discount = useMemo(() => {
    return promotionDiscount + couponDiscount;
  }, [couponDiscount, promotionDiscount]);

  const closeBag = useCallback(() => {
    setIsHidden(true);
  }, []);

  const openBag = useCallback(() => {
    if (router.pathname !== '/carrinho') {
      setIsOpen(true);
    }
  }, [router]);

  useEffect(() => {
    if (isHidden) {
      setTimeout(() => {
        setIsHidden(false);
        setIsOpen(false);
      }, 300);
    }
  }, [isHidden]);

  const handleClickGoToCart = useCallback(() => {
    router.push('/carrinho');
    closeBag();
  }, [router, closeBag]);

  const handleClickGoToCheckout = useCallback(() => {
    router.push('/checkout');
    closeBag();
    track('InitiateCheckout');
  }, [router, closeBag, track]);

  const selectShipping = useCallback((shipping: IShippingOption) => {
    setShippingSelected(shipping);
  }, []);

  const calculateShipping = useCallback(
    async (toPostalCode: string): Promise<void> => {
      if (!toPostalCode) {
        return;
      }

      if (
        cart &&
        (toPostalCode !== postalCode || (shippingOptions || []).length === 0)
      ) {
        try {
          setIsCalculatingShipping(true);

          const shipping = await shippingCalculate({
            toPostalCode: toPostalCode.replace(/\D/g, ''),
            cart: cart._id,
          });

          setPostalCode(toPostalCode);
          setShippingOptions(shipping);
          setShippingSelected((prevState) => {
            const findShipping =
              prevState &&
              shipping.find(
                (item) =>
                  `${prevState.code}:${prevState.name}`.toLowerCase() ===
                  `${item.code}:${item.name}`.toLowerCase()
              );

            return (
              findShipping ||
              (prevState?.code === 'pickup' ? prevState : undefined)
            );
          });
        } catch (error) {
          openToast(`Ops, não foi possível carregar as opções de entrega.`);
        } finally {
          setIsCalculatingShipping(false);
        }
      }
    },
    [cart, openToast, postalCode, shippingOptions]
  );

  const clearShipping = useCallback(() => {
    setShippingOptions(undefined);

    setShippingSelected((prevState) =>
      prevState?.code === 'pickup' ? prevState : undefined
    );
  }, []);

  const getManufacturingTime = useCallback(() => {
    if (
      storeSettings &&
      storeSettings.shipping &&
      storeSettings.shipping.manufacturingTime &&
      storeSettings.shipping.manufacturingTime > 0
    ) {
      return storeSettings?.shipping?.manufacturingTime;
    }

    const itemWithManufacturingTime = cart?.items
      .filter(
        (item) =>
          item.product?.manufacturingTime && item.product?.manufacturingTime > 0
      )
      .map((item) => item.product!.manufacturingTime) as number[];

    if (!itemWithManufacturingTime || itemWithManufacturingTime.length <= 0)
      return 0;

    return Math.max(...itemWithManufacturingTime);
  }, [cart?.items, storeSettings]);

  const createCart = useCallback(
    async (items: ICartItem[]) => {
      let shouldRemoveCart = false;

      try {
        const cartId = uuid.v4();

        setCart({
          _id: cartId,
          items: items.map((item) => ({
            _id: item._id,
            quantity: item.quantity,
            fullPrice: item.fullPrice,
            product: item.product,
            unitPrice: item.unitPrice,
          })),
          total: items.reduce(
            (acc, item) =>
              acc + (item.fullPrice || (item.unitPrice || 0) * item.quantity),
            0
          ),
          manufacturingTime: 0,
          isCreating: true,
        });

        shouldRemoveCart = true;

        openBag();

        const newCart = await createCartService({
          items,
          _id: cartId,
        });

        setCart((prevState) => ({
          ...prevState!,
          _id: newCart._id,
          isCreating: false,
        }));
      } catch (error) {
        if (shouldRemoveCart) {
          setCart(undefined);
        }
      }
    },
    [openBag]
  );

  const addProductInCart = useCallback(
    async (product: IProduct, quantity = 1) => {
      const oldItems = [...(cart?.items || [])];

      const unitPrice =
        (product.promotionalPrice && product.promotionalPrice !== product.price
          ? product.promotionalPrice
          : product.price) || 0;

      const fullPrice = unitPrice * quantity;

      try {
        if (!cart) {
          await createCart([
            {
              quantity,
              product,
              fullPrice,
              unitPrice,
              _id: product._id,
            },
          ]);
        } else {
          let newItems = [...cart.items];

          const hasItem = cart.items.find(
            (cartItem) => cartItem._id === product._id
          );

          if (hasItem) {
            newItems = newItems.map((cartItem) => {
              if (cartItem._id === product._id) {
                const newQuantity = cartItem.quantity + quantity;

                return {
                  ...cartItem,
                  quantity: newQuantity,
                  fullPrice: (cartItem.unitPrice || 0) * newQuantity,
                };
              }

              return cartItem;
            });
          } else {
            newItems = [
              ...newItems,
              {
                _id: product._id,
                quantity,
                product,
                unitPrice,
                fullPrice,
              },
            ];
          }

          newItems = newItems.filter((cartItem) => cartItem.quantity > 0);

          setCart((prevState) => ({
            ...prevState!,
            items: newItems.map((item) => ({
              _id: item._id,
              quantity: item.quantity,
              fullPrice: item.fullPrice,
              product: item.product,
              unitPrice: item.unitPrice,
            })),
            total: newItems.reduce(
              (acc, item) =>
                acc + (item.fullPrice || (item.unitPrice || 0) * item.quantity),
              0
            ),
          }));

          openBag();

          await addProductInCartService(cart._id, {
            items: newItems,
          });

          calculateShipping(postalCode);
        }

        if (quantity > 0) {
          track('AddToCart');
        }

        if (
          typeof window.gtag !== 'undefined' &&
          storeSettings &&
          !!googleAnalyticsSettings?.['tracking-id']
        ) {
          const formattedCategory =
            product.categories &&
            product.categories.length > 0 &&
            product.categories?.reduce((acc, category) => {
              let newAcc = acc || '';

              newAcc = `${newAcc ? '/' : ''}${category.name}`;

              return newAcc;
            }, '');

          window.gtag(
            'event',
            quantity < 0 ? 'remove_from_cart' : 'add_to_cart',
            {
              items: [
                {
                  id: product.sku || product._id,
                  name: product.relatedWith
                    ? product.productName
                    : product.name,
                  category: formattedCategory,
                  variant: product.relatedWith ? product.name : null,
                  quantity,
                  price:
                    (product.promotionalPrice || 0) < product.price
                      ? product.promotionalPrice
                      : product.price,
                },
              ],
            }
          );
        }
      } catch (e: any) {
        setCart((prevState) => ({
          ...prevState!,
          items: oldItems,
        }));

        if (
          e.response?.data?.tag === 'PRODUCT_OUT_OF_STOCK' &&
          product.stock > 0
        ) {
          openToast(
            `Ops, só possuímos ${product.stock} deste produto em estoque!`
          );
        } else if (e.response?.data?.tag === 'PRODUCT_OUT_OF_STOCK') {
          openToast('Ops, este produto está esgotado!');
        } else {
          openToast('Ops, houve um erro inesperado');
        }
      }
    },
    [
      cart,
      storeSettings,
      googleAnalyticsSettings,
      createCart,
      openBag,
      calculateShipping,
      postalCode,
      track,
      openToast,
    ]
  );

  const changeItemQuantity = useCallback(
    async (item: ICartItem, quantity: number) => {
      if (!cart || cart.isCreating) return;

      const oldCart = { ...cart };

      try {
        const newItems = cart.items.map((cartItem) => {
          if (cartItem._id === item._id) {
            return { ...cartItem, quantity };
          }

          return cartItem;
        });

        const total = newItems.reduce(
          (acc, cartItem) =>
            acc +
            (cartItem.fullPrice ||
              (cartItem.unitPrice || 0) * cartItem.quantity),
          0
        );

        setCart((prevState) => ({
          ...prevState!,
          items: newItems,
          total,
        }));

        await addProductInCartService(cart._id, {
          items: newItems,
        });

        calculateShipping(postalCode);
      } catch (e: any) {
        setCart(oldCart);

        if (
          e.response.data.tag === 'PRODUCT_OUT_OF_STOCK' &&
          item.product &&
          item.product.stock > 0
        ) {
          openToast(
            `Ops, só possuímos ${item.product.stock} deste produto em estoque!`
          );
        } else if (e.response.data.tag === 'PRODUCT_OUT_OF_STOCK') {
          openToast('Ops, este produto está esgotado!');
        } else {
          openToast('Ops, houve um erro inesperado');
        }
      }
    },
    [calculateShipping, cart, openToast, postalCode]
  );

  const addOneItem = useCallback(
    (product: ICartItem | IProduct) => {
      addProductInCart(product as IProduct, 1);
    },
    [addProductInCart]
  );

  const removeOneItem = useCallback(
    (product: ICartItem | IProduct) => {
      addProductInCart(product as IProduct, -1);
    },
    [addProductInCart]
  );

  const removeItem = useCallback(
    (item: ICartItem) => {
      addProductInCart(item.product as IProduct, -item.quantity);
    },
    [addProductInCart]
  );

  const handleClickItem = (product: IProduct): void => {
    // if (product.relatedWith?.tag) {
    //   const attributes: Record<string, string> = {};
    //   product.attributes?.forEach((attribute) => {
    //     attributes[replaceSpecialCharacters(attribute.name)] =
    //       replaceSpecialCharacters(attribute.value?.name || '');
    //   });
    //
    //   router.push(
    //     `/produtos/${product.relatedWith?.tag}?${qs.stringify(attributes)}`
    //   );
    // } else {
    //   router.push(`/produtos/${product.tag}`);
    // }
    router.push(getProductLink(product));

    closeBag();
  };

  const applyCoupon = useCallback(
    async (couponName: string): Promise<void> => {
      try {
        setIsAplyingCoupon(true);

        const newCoupon = await getCouponByName({
          name: couponName,
          cart: cart?._id,
        });

        setCoupon(newCoupon);

        if (newCoupon) {
          if (
            typeof window.gtag !== 'undefined' &&
            storeSettings &&
            !!googleAnalyticsSettings?.['tracking-id']
          ) {
            const formattedItems =
              cart &&
              cart.items &&
              cart.items.length > 0 &&
              cart.items
                .map((item, index) => {
                  if (!item.product) return undefined;

                  const { categories, _id, relatedWith, name, sku } =
                    item.product;

                  const formattedCategory =
                    categories &&
                    categories.length > 0 &&
                    categories?.reduce((acc, category) => {
                      let newAcc = acc || '';

                      newAcc = `${newAcc ? '/' : ''}${category.name}`;

                      return newAcc;
                    }, '');

                  return {
                    id: sku || _id,
                    name: relatedWith ? relatedWith.name : name,
                    category: formattedCategory,
                    list_position: index + 1,
                    variant: relatedWith ? name : null,
                    quantity: item.quantity,
                    price: item.unitPrice,
                  };
                })
                .filter((item) => item);

            if (formattedItems && formattedItems.length > 0) {
              window.gtag('event', 'checkout_progress', {
                items: formattedItems,
                coupon: newCoupon,
              });
            }
          }
        } else {
          openToast(`Ops, o cupom "${couponName}" não é válido!`);
        }
      } catch (e) {
        setCoupon(null);
        openToast(
          e?.response?.data?.displayMessage || 'Ops, houve um erro inesperado!'
        );
      } finally {
        setIsAplyingCoupon(false);
      }
    },
    [cart, googleAnalyticsSettings, openToast, storeSettings]
  );

  useEffect(() => {
    if (cart) {
      localStorage.setItem('@PraVendas:cart', JSON.stringify(cart));
      if (postalCode?.length === 10 && !shippingOptions) {
        calculateShipping(postalCode);
      }
    }
  }, [postalCode, shippingOptions, calculateShipping, cart]);

  const clearCart = useCallback(() => {
    setCart(undefined);
  }, []);

  const formattedCart = useMemo(() => {
    if (!cart) return undefined;

    return {
      ...cart,
      manufacturingTime: getManufacturingTime(),
    } as ICart;
  }, [cart, getManufacturingTime]);

  const selectPaymentMethod = useCallback((paymentMethod: TPaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
  }, []);

  return (
    <BagContext.Provider
      value={{
        openBag,
        closeBag,
        addProductInCart,
        itemsQuantity,
        cart: formattedCart,
        addOneItem,
        removeOneItem,
        removeItem,
        calculateShipping,
        selectShipping,
        shippingOptions,
        shippingSelected,
        postalCode,
        applyCoupon,
        coupon,
        isAplyingCoupon,
        clearCart,
        clearShipping,
        onClickItem: handleClickItem,
        selectPaymentMethod,
        selectedPaymentMethod,
        discount,
        isCalculatingShipping,
      }}
    >
      <Bag
        goToCart={handleClickGoToCart}
        goToCheckout={handleClickGoToCheckout}
        isOpen={isOpen}
        isHidden={isHidden}
        handleClose={closeBag}
        cart={formattedCart}
        addOneItem={addOneItem}
        removeOneItem={removeOneItem}
        onClickItem={handleClickItem}
        onClickCalculateShipping={calculateShipping}
        shippingOptions={shippingOptions}
        selectShipping={selectShipping}
        shippingSelected={shippingSelected}
        postalCode={postalCode}
        discount={discount}
        pickup={storeSettings?.shipping?.pickup}
        removeItem={removeItem}
        changeItemQuantity={changeItemQuantity}
      />
      {children}
    </BagContext.Provider>
  );
};

function useBag(): IBagContextData {
  return useContext(BagContext);
}

export { BagProvider, useBag };
