import { useModal } from 'hooks/useModal';

import { ModalForgotPassword } from 'components/Modal/ModalForgotPassword/index';

interface IModalContextData {
  openModal: () => void;
  closeModal: () => void;
}

function useModalForgotPassword(): IModalContextData {
  const { openModal, closeModal } = useModal();

  const handleOpenModalForgotPassword = (): void => {
    openModal(ModalForgotPassword, {
      title: 'ESQUECI MINHA SENHA',
      size: 'small',
    });
  };

  return {
    openModal: handleOpenModalForgotPassword,
    closeModal,
  };
}

export { useModalForgotPassword };
