export function replaceSpecialCharacters(text: string): string {
  let textFormatted = text;

  textFormatted = textFormatted.replace(/[áàãâ]/g, 'a');
  textFormatted = textFormatted.replace(/[ÁÀÃÂ]/g, 'A');

  textFormatted = textFormatted.replace(/[éèê]/g, 'e');
  textFormatted = textFormatted.replace(/[ÉÈÊ]/g, 'E');

  textFormatted = textFormatted.replace(/[íìî]/g, 'i');
  textFormatted = textFormatted.replace(/[ÍÌÎ]/g, 'I');

  textFormatted = textFormatted.replace(/[óòõô]/g, 'o');
  textFormatted = textFormatted.replace(/[ÓÒÕÔ]/g, 'O');

  textFormatted = textFormatted.replace(/[úùû]/g, 'u');
  textFormatted = textFormatted.replace(/[ÚÙÛ]/g, 'U');

  textFormatted = textFormatted.replace(/[Ç]/g, 'C');
  textFormatted = textFormatted.replace(/[ç]/g, 'c');

  textFormatted = textFormatted.toLowerCase();

  return textFormatted;
}
