import dynamic from 'next/dynamic';

import { IModal } from '@pra-vendas-themes/interfaces/IModal';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const Modals = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "ModalDefault" */ '@pra-vendas-themes/default/components/Modal'
      )
  ),
};

type TUseModalData = {
  Modal: WithChildren<IModal>;
};

export const useModalTheme = (theme: TThemes): TUseModalData => {
  const Modal = Modals[theme] as WithChildren<IModal>;

  return { Modal };
};
