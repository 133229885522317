import { IModal } from '@pra-vendas-themes/interfaces/IModal';

import { useComponentsTheme } from 'hooks/useComponentsTheme';

import { WithChildren } from 'types/WithChildren';

export const Modal: WithChildren<IModal> = ({ children, ...props }) => {
  const { Modal: ModalTheme } = useComponentsTheme();

  return <ModalTheme {...props}>{children}</ModalTheme>;
};
