import dynamic from 'next/dynamic';

import { ICartDetail } from '@pra-vendas-themes/interfaces/ICartDetail';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const CartDetails = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "CartDetailDefault" */ '@pra-vendas-themes/default/components/CartDetail'
      )
  ),
};

type TUseCartDetailData = {
  CartDetail: WithChildren<ICartDetail>;
};

export const useCartDetailTheme = (theme: TThemes): TUseCartDetailData => {
  const CartDetail = CartDetails[theme] as WithChildren<ICartDetail>;

  return { CartDetail };
};
