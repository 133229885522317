import { api } from 'services/api';

import { IHostnameParam } from 'types/IHostnameParam';

export type TGetCouponByNameParams = IHostnameParam & {
  name: string;
  cart: string;
};

export async function getCouponByName(
  config: TGetCouponByNameParams
): Promise<any> {
  const { hostname, name, cart } = config;

  const response = await api.get(`/coupons/name/${name}`, {
    ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
    params: { cart },
  });

  return response.data;
}
