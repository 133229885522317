import { FC } from 'react';
import { useState } from 'react';

import { useRouter } from 'next/router';

import { IHeader } from '@pra-vendas-themes/interfaces/IHeader';

import { useSiteSettings } from 'hooks/useSiteSettings';
import { useComponentsTheme } from 'hooks/useComponentsTheme';
import { useBag } from 'hooks/useBag';
import { useAuth } from 'hooks/useAuth';
import { useFacebookPixel } from 'hooks/facebookPixel';

import { ICategory } from 'types/ICategory';

import { useModalSignIn } from 'components/Modal/ModalSignIn/hook';

export const Header: FC<IHeader> = () => {
  const { Header: HeaderTheme } = useComponentsTheme();
  const router = useRouter();
  const { openModal } = useModalSignIn();
  const { openBag, itemsQuantity } = useBag();
  const { track } = useFacebookPixel();

  const { categories, storeSettings } = useSiteSettings();
  const { logo } = storeSettings || {};

  const { isLogged, signOut } = useAuth();

  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);

  const handleClickUser = (): void => {
    if (isLogged) {
      setIsUserMenuVisible((prev) => !prev);
    } else {
      openModal();
      setIsUserMenuVisible(false);
    }
  };

  const handleClickBag = (): void => {
    openBag();
  };

  const handleClickFavorite = (): void => {
    router.push('/favoritos');
  };

  const handleClickLogout = (): void => {
    signOut();
  };

  const handleSearchClick = (value: string): void => {
    track('Search');
    router.push(`/produtos?pesquisa=${value}`);
  };

  const handleClickCategory = (category: ICategory): void => {
    router.push(`/categorias/${category.tag}`);
  };

  return (
    <HeaderTheme
      logo={logo}
      isLogged={isLogged}
      searchPlaceholder="Encontre aqui o produto que você precisa"
      onSearchClick={handleSearchClick}
      onClickUser={handleClickUser}
      onClickFavorite={handleClickFavorite}
      onClickBag={handleClickBag}
      isUserMenuVisible={isUserMenuVisible}
      authMenuLinks={{
        account: '/conta',
        address: '/conta/endereco',
        orders: '/conta/pedidos',
      }}
      logout={handleClickLogout}
      categories={categories}
      onClickCategory={handleClickCategory}
      itemsQuantityBag={itemsQuantity}
    />
  );
};
