export const theme = {
  maxWidth: '1100px',
  borderRadius: '2px',
  colors: {
    primary: '#222',

    background: '#fff',
    backgroundHover: '#f4f4f4',
    loadingHover: 'rgb(238, 238, 238)',

    danger: '#e74c3c',
    success: '#07bc0c',
    warning: '#fdd03b',
    info: '#3498db',

    highlight: '#ff0',

    border: '#e4e4e4',

    textPrimary: '#222',
    textSecondary: '#666',

    textDark: '#222',
    textLight: '#fff',

    placeholder: '#aaa',
  },
  footer: {
    background: '#f6f6f6',
  },
  header: {
    background: '#fff',
  },
  input: {
    primary: '#fff',
    secondary: '#f6f6f6',
    disabled: '#f1f1f1',
  },
  font: {
    xSmall: '10px',
    small: '12px',
    medium: '14px',
    large: '16px',
    xLarge: '18px',
    xxLarge: '24px',
  },
  spacing: {
    quark: '2px',
    nano: '4px',
    small: '8px',
    medium: '16px',
    large: '24px',
    xLarge: '32px',
    xxLarge: '40px',
    xxxLarge: '48px',
    giant: '80px',
  },
  button: {
    xSmall: '4px 8px',
    small: '8px 16px',
    medium: '12px 16px',
    large: '8px 16px',
    xLarge: '16px 24px',
  },
  modal: {
    small: '360px',
    medium: '500px',
    large: '700px',
  },
};
