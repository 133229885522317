import { IToast } from '@pra-vendas-themes/interfaces/IToast';

import { useComponentsTheme } from 'hooks/useComponentsTheme';

import { WithChildren } from 'types/WithChildren';

export const Toast: WithChildren<IToast> = ({ children, ...props }) => {
  const { Toast: ToastTheme } = useComponentsTheme();

  return <ToastTheme {...props}>{children}</ToastTheme>;
};
