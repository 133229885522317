import dynamic from 'next/dynamic';

import { ITitleWithSort } from '@pra-vendas-themes/interfaces/ITitleWithSort';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const TitlesWithSort = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "TitleWithSortDefault" */ '@pra-vendas-themes/default/components/TitleWithSort'
      )
  ),
};

type TUseTitleWithSortData = {
  TitleWithSort: WithChildren<ITitleWithSort>;
};

export const useTitleWithSortTheme = (
  theme: TThemes
): TUseTitleWithSortData => {
  const TitleWithSort = TitlesWithSort[theme] as WithChildren<ITitleWithSort>;

  return { TitleWithSort };
};
