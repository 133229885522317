import dynamic from 'next/dynamic';

import { IHeader } from '@pra-vendas-themes/interfaces/IHeader';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const Headers = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "HeaderDefault" */ '@pra-vendas-themes/default/components/Header'
      )
  ),
};

type TUseHeaderData = {
  Header: WithChildren<IHeader>;
};

export const useHeaderTheme = (theme: TThemes): TUseHeaderData => {
  const Header = Headers[theme] as WithChildren<IHeader>;

  return { Header };
};
