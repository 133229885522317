import { useModal } from 'hooks/useModal';

import { ModalSignIn } from 'components/Modal/ModalSignIn/index';

interface IModalContextData {
  openModal: () => void;
  closeModal: () => void;
}

function useModalSignIn(): IModalContextData {
  const { openModal, closeModal } = useModal();

  const handleOpenModalSignIn = (): void => {
    openModal(ModalSignIn, { title: 'JÁ SOU CLIENTE', size: 'small' });
  };

  return {
    openModal: handleOpenModalSignIn,
    closeModal,
  };
}

export { useModalSignIn };
