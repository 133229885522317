import dynamic from 'next/dynamic';

import { IAlert } from '@pra-vendas-themes/interfaces/IAlert';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const Alerts = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "AlertDefault" */ '@pra-vendas-themes/default/components/Alert'
      )
  ),
};

type TUseAlertData = {
  Alert: WithChildren<IAlert>;
};

export const useAlertTheme = (theme: TThemes): TUseAlertData => {
  const Alert = Alerts[theme] as WithChildren<IAlert>;

  return { Alert };
};
