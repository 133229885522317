import dynamic from 'next/dynamic';

import { IFooter } from '@pra-vendas-themes/interfaces/IFooter';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const Footers = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "FooterDefault" */ '@pra-vendas-themes/default/components/Footer'
      )
  ),
};

type TUseFooterData = {
  Footer: WithChildren<IFooter>;
};

export const useFooterTheme = (theme: TThemes): TUseFooterData => {
  const Footer = Footers[theme] as WithChildren<IFooter>;

  return { Footer };
};
