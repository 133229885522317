import dynamic from 'next/dynamic';

import { ILayout } from '@pra-vendas-themes/interfaces/ILayout';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const Layouts = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "LayoutDefault" */ '@pra-vendas-themes/default'
      )
  ),
};

type TUseLayoutData = {
  Layout: WithChildren<ILayout>;
};

export const useLayoutTheme = (theme: TThemes): TUseLayoutData => {
  const Layout = Layouts[theme] as WithChildren<ILayout>;

  return { Layout };
};
