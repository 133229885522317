import { useModal } from 'hooks/useModal';

import { ModalSignUp } from 'components/Modal/ModalSignUp/index';

interface IModalContextData {
  openModal: (initialValues?: any) => void;
  closeModal: () => void;
}

function useModalSignUp(): IModalContextData {
  const { openModal, closeModal } = useModal();

  const handleOpenModalSignUp = (initialValues?: any): void => {
    openModal(ModalSignUp, {
      title: 'CRIAR UMA CONTA',
      size: 'small',
      props: {
        initialValues,
      },
    });
  };

  return {
    openModal: handleOpenModalSignUp,
    closeModal,
  };
}

export { useModalSignUp };
