import dynamic from 'next/dynamic';

import { IToast } from '@pra-vendas-themes/interfaces/IToast';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const Toasts = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "ToastDefault" */ '@pra-vendas-themes/default/components/Toast'
      )
  ),
};

type TUseToastData = {
  Toast: WithChildren<IToast>;
};

export const useToastTheme = (theme: TThemes): TUseToastData => {
  const Toast = Toasts[theme] as WithChildren<IToast>;

  return { Toast };
};
