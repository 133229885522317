import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('Por favor, insira seu nome completo.'),
  email: Yup.string()
    .required('Por favor, insira um e-mail.')
    .email('Por favor, insira um e-mail válido'),
  password: Yup.string().required('Por favor, insira sua senha.'),
  confirmPassword: Yup.string()
    .required('Por favor, insira sua senha novamente.')
    .oneOf([Yup.ref('password')], 'Ops, as senhas não coincidem.'),
});
