import { FC } from 'react';

import { useFormik } from 'formik';

import { FormField } from 'components/Form/FormField';
import { Form } from 'components/Form';
import { Button } from 'components/Button';

import validationSchema from './validationSchema';

export type TFormSignInValues = {
  email: string;
  password: string;
};

type TFormSignIn = {
  onSubmit: (values: TFormSignInValues) => Promise<void>;
  isLoading?: boolean;
};

const FormSignIn: FC<TFormSignIn> = ({ isLoading, onSubmit }) => {
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      email: '',
      password: '',
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      renderActions={() => (
        <Button
          isLoading={isLoading}
          width={1}
          font="small"
          lineHeight="medium"
          type="submit"
        >
          ENTRAR
        </Button>
      )}
    >
      <FormField
        autoFocus
        label="E-mail"
        placeholder="Digite seu e-mail"
        name="email"
        value={values.email}
        error={errors.email && touched.email && errors.email}
        onChange={handleChange}
      />
      <FormField
        label="Senha"
        placeholder="Digite sua senha"
        name="password"
        type="password"
        value={values.password}
        error={errors.password && touched.password && errors.password}
        onChange={handleChange}
      />
    </Form>
  );
};

export default FormSignIn;
