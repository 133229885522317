import { ThemeProvider } from 'styled-components';
import { theme } from '@pra-vendas-themes/styles/theme';

import { ToastProvider } from 'hooks/useToast';
import { useSiteSettings } from 'hooks/useSiteSettings';
import { ModalProvider } from 'hooks/useModal';
import { ComponentsThemeProvider } from 'hooks/useComponentsTheme';
import { BagProvider } from 'hooks/useBag';
import { AuthProvider } from 'hooks/useAuth';

import { WithChildren } from 'types/WithChildren';

import { ScrollToTop } from 'components/ScrollToTop';

const AppProvider: WithChildren = ({ children }) => {
  const { storeSettings } = useSiteSettings();
  const { theme: themeStore } = storeSettings || {};

  return (
    <ScrollToTop>
      <ThemeProvider
        theme={{
          ...theme,
          colors: {
            ...theme.colors,
            primary: themeStore?.colors.primary || theme.colors.primary,
            textPrimary: themeStore?.colors.primary || theme.colors.primary,
          },
        }}
      >
        <ComponentsThemeProvider>
          <AuthProvider>
            <ToastProvider>
              <ModalProvider>
                <BagProvider>{children}</BagProvider>
              </ModalProvider>
            </ToastProvider>
          </AuthProvider>
        </ComponentsThemeProvider>
      </ThemeProvider>
    </ScrollToTop>
  );
};

export default AppProvider;
