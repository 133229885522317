/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';

import { useSiteSettings } from 'hooks/useSiteSettings';

import { Wrapper } from './styles';

type TFloatWhatsapp = {
  whatsappNumber?: string;
};

const FloatWhatsapp: FC<TFloatWhatsapp> = ({ whatsappNumber }) => {
  const { jivoChatSettings } = useSiteSettings();

  const [hasJivoChat, setHasJivoChat] = useState<boolean>(false);

  useEffect(() => {
    if (!jivoChatSettings?.code) return;

    const interval = setInterval(() => {
      if (typeof document !== 'undefined') {
        const jivoChatElement =
          document.getElementsByTagName('jdiv')?.length > 0;

        if (jivoChatElement) {
          setHasJivoChat(true);
          clearInterval(interval);
        }
      }
    }, 1);

    setTimeout(() => {
      clearInterval(interval);
    }, 5000);
  }, [jivoChatSettings?.code]);

  if (!whatsappNumber) return null;

  const link = `https://wa.me/55${whatsappNumber.replace(/\D/g, '')}`;

  return (
    <Wrapper href={link} target="_blank" hasJivoChat={hasJivoChat}>
      <FaWhatsapp />
    </Wrapper>
  );
};

export default FloatWhatsapp;
