import { FC, useState } from 'react';

import { useToast } from 'hooks/useToast';
import { useAuth } from 'hooks/useAuth';

import { useModalSignUp } from 'components/Modal/ModalSignUp/hook';
import FormSignIn, { TFormSignInValues } from 'components/Forms/FormSignIn';

import { Text, ButtonLink } from './styles';

import { useModalForgotPassword } from '../ModalForgotPassword/hook';

type TModalSignIn = {
  handleClose?: () => void;
};

export const ModalSignIn: FC<TModalSignIn> = ({ handleClose }) => {
  const { openModal: openModalSignUp } = useModalSignUp();
  const { openModal: openModalForgotPassword } = useModalForgotPassword();
  const { openToast } = useToast();
  const { signIn } = useAuth();
  const [tempData, setTempData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const handleGoToSignUp = (): void => {
    openModalSignUp(tempData);
  };

  const handleGoToForgotPassword = (): void => {
    openModalForgotPassword();
  };

  const onSubmit = async (submitValues: TFormSignInValues): Promise<void> => {
    const { email, password } = submitValues;

    try {
      setIsLoading(true);
      setTempData({ email, password });

      await signIn({
        email,
        password,
      });

      handleClose?.();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      if (e.response.status === 401) {
        openToast(`Ops, e-mail ou senha incorreto(s)!<br />Tente novamente.`);
      } else {
        openToast('Ops, ocorreu um erro inesperado.');
      }
    }
  };

  return (
    <>
      <FormSignIn isLoading={isLoading} onSubmit={onSubmit} />
      <Text>
        Não tem uma conta?{' '}
        <ButtonLink onClick={handleGoToSignUp}>Cadastre-se</ButtonLink>
      </Text>

      <Text>
        Esqueceu sua senha?{' '}
        <ButtonLink onClick={handleGoToForgotPassword}>Recupere</ButtonLink>
      </Text>
    </>
  );
};
