/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { api } from 'services/api/index';

import { IPromotion } from 'types/IPromotion';
import { IHostnameParam } from 'types/IHostnameParam';

export async function getPromotions(
  config: IHostnameParam
): Promise<IPromotion[]> {
  try {
    const { hostname } = config;

    const response = await api.get(`/promotions/available`, {
      ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
    });

    return response.data;
  } catch (error) {
    console.error('[getPromotions]', error.message);
    return [];
  }
}
