/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { api } from 'services/api/index';

import { INewsletter, INewsletterSubscription } from 'types/INewsletter';
import { IHostnameParam } from 'types/IHostnameParam';

export async function getNewsletterSettings(
  config: IHostnameParam
): Promise<INewsletter> {
  try {
    const { hostname } = config;

    const response = await api.get(`/newsletter-settings`, {
      ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
    });

    return response.data;
  } catch (error) {
    console.error('[getNewsletterSettings]', error.message);
    return {} as INewsletter;
  }
}

type TSubscribeNewsletterParams = IHostnameParam & {
  newsletter: INewsletterSubscription;
};

export async function subscribeNewsletter(
  config: TSubscribeNewsletterParams
): Promise<INewsletter> {
  const { hostname, newsletter } = config;

  const response = await api.post(`/newsletter/subscribe`, newsletter, {
    ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
  });

  return response.data;
}
