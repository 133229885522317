import { FC } from 'react';

import { useFormik } from 'formik';

import { FormField } from 'components/Form/FormField';
import { Form } from 'components/Form';
import { Button } from 'components/Button';

import validationSchema from './validationSchema';

export type TFormSignUpValues = {
  name: string;
  email: string;
  password: string;
  confirmPassword?: string;
};

type TFormSignUp = {
  onSubmit: (values: TFormSignUpValues) => Promise<void>;
  isLoading?: boolean;
  initialValues?: TFormSignUpValues;
};

const FormSignUp: FC<TFormSignUp> = ({
  isLoading,
  initialValues,
  onSubmit,
}) => {
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      ...initialValues,
    },
  });

  return (
    <Form
      onSubmit={handleSubmit}
      renderActions={() => (
        <Button
          isLoading={isLoading}
          width={1}
          font="small"
          lineHeight="medium"
          type="submit"
        >
          CRIAR CONTA
        </Button>
      )}
    >
      <FormField
        autoFocus
        label="Nome completo"
        placeholder="Digite seu nome completo"
        name="name"
        value={values.name}
        error={errors.name && touched.name && errors.name}
        onChange={handleChange}
      />
      <FormField
        label="E-mail"
        placeholder="Digite seu e-mail"
        name="email"
        value={values.email}
        error={errors.email && touched.email && errors.email}
        onChange={handleChange}
      />
      <FormField
        label="Senha"
        placeholder="Digite sua senha"
        name="password"
        type="password"
        value={values.password}
        error={errors.password && touched.password && errors.password}
        onChange={handleChange}
      />
      <FormField
        label="Confirmação de senha"
        placeholder="Digite sua senha novamente"
        name="confirmPassword"
        type="password"
        value={values.confirmPassword}
        error={
          errors.confirmPassword &&
          touched.confirmPassword &&
          errors.confirmPassword
        }
        onChange={handleChange}
      />
    </Form>
  );
};

export default FormSignUp;
