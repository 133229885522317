import { ILayout } from '@pra-vendas-themes/interfaces/ILayout';

import { useComponentsTheme } from 'hooks/useComponentsTheme';

import { WithChildren } from 'types/WithChildren';

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Cookies } from 'components/Cookies';

type TLayout = ILayout;

export const Layout: WithChildren<TLayout> = ({ children }) => {
  const { Layout: LayoutTheme } = useComponentsTheme();

  return (
    <LayoutTheme>
      <Header />
      {children}
      <Footer />
      <Cookies />
    </LayoutTheme>
  );
};
