/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable eqeqeq */

export enum ValidatePhoneNumberTypesEnum {
  'phone_number' = 'phone_number',
  'cell_phone' = 'cell_phone',
}

export function validatePhoneNumber(
  phoneNumber?: string,
  types: ValidatePhoneNumberTypesEnum[] = [
    ValidatePhoneNumberTypesEnum.phone_number,
    ValidatePhoneNumberTypesEnum.cell_phone,
  ]
): boolean {
  if (!phoneNumber) return false;

  let newPhoneNumber = phoneNumber.replace(/[^\d]+/g, '');

  newPhoneNumber = newPhoneNumber.replace(/_/g, '');

  const hasPhoneNumber = types.includes(
    ValidatePhoneNumberTypesEnum.phone_number
  );
  const hasCellPhone = types.includes(ValidatePhoneNumberTypesEnum.cell_phone);

  if (
    hasPhoneNumber &&
    hasCellPhone &&
    (newPhoneNumber.length < 10 || newPhoneNumber.length > 11)
  ) {
    return false;
  }

  if (hasPhoneNumber && !hasCellPhone && newPhoneNumber.length !== 10) {
    return false;
  }

  if (hasCellPhone && !hasPhoneNumber && newPhoneNumber.length !== 11) {
    return false;
  }

  return true;
}
