import { createContext, useContext, useState, useEffect } from 'react';

import { ColorScheme } from 'styled-components';
import {
  IToastProps,
  TConfigToast,
} from '@pra-vendas-themes/interfaces/IToast';

import { WithChildren } from 'types/WithChildren';

import { Toast } from 'components/Toast';

interface IToastContextData {
  openToast: (
    title: string,
    config?: { timeout?: number; color?: ColorScheme }
  ) => void;
  closeToast: () => void;
}

const ToastContext = createContext<IToastContextData>({} as IToastContextData);

const ToastProvider: WithChildren = ({ children }) => {
  const [toast, setToast] = useState<IToastProps | undefined>(undefined);
  const [isAnimationClosed, setIsAnimationClosed] = useState(false);

  const closeToast = (): void => {
    if (!isAnimationClosed) {
      if (window.timeoutFunction) clearTimeout(window.timeoutFunction);
      setIsAnimationClosed(true);
    }
  };

  const openToast = (title: string, config?: TConfigToast): void => {
    const { timeout = 2500 } = config || {};
    if (!toast) {
      setToast({
        title,
        config: {
          ...config,
          timeout,
        },
      });

      window.timeoutFunction = setTimeout(() => {
        closeToast();
      }, timeout);
    }
  };

  useEffect(() => {
    if (isAnimationClosed) {
      setTimeout(() => {
        setToast(undefined);
        setIsAnimationClosed(false);
      }, 1000);
    }
  }, [isAnimationClosed]);

  return (
    <ToastContext.Provider value={{ openToast, closeToast }}>
      {toast && (
        <Toast
          isAnimationClosed={isAnimationClosed}
          handleClose={closeToast}
          {...toast}
        />
      )}
      {children}
    </ToastContext.Provider>
  );
};

function useToast(): IToastContextData {
  return useContext(ToastContext);
}

export { ToastProvider, useToast };
