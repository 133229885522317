/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useRouter } from 'next/router';

import { WithChildren } from 'types/WithChildren';
import { IFacebookPixelSettings } from 'types/IAppConfiguration';

type TTrackEvent =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'CompleteRegistration'
  | 'Contact'
  | 'CustomizeProduct'
  | 'Donate'
  | 'FindLocation'
  | 'InitiateCheckout'
  | 'Lead'
  | 'Purchase'
  | 'Schedule'
  | 'Search'
  | 'StartTrial'
  | 'SubmitApplication'
  | 'Subscribe'
  | 'ViewContent';
interface IFacebookPixelContextData {
  track(event: TTrackEvent, params?: Record<string, any>): void;
}

const FacebookPixelContext = createContext<IFacebookPixelContextData>(
  {} as IFacebookPixelContextData
);

interface FacebookPixelProviderProps {
  settings: IFacebookPixelSettings | undefined;
}

const FacebookPixelProvider: WithChildren<FacebookPixelProviderProps> = ({
  children,
  settings: _facebookPixelSettings,
}) => {
  const router = useRouter();

  const [facebookPixelSettings, setFacebookPixelSettings] = useState<
    IFacebookPixelSettings | undefined
  >(_facebookPixelSettings);

  useEffect(() => {
    if (_facebookPixelSettings)
      setFacebookPixelSettings(_facebookPixelSettings);
  }, [_facebookPixelSettings]);

  const init = useCallback(() => {
    if (!facebookPixelSettings?.['pixel-id']) return;

    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(facebookPixelSettings['pixel-id'], undefined, {
          autoConfig: true,
          debug: false,
        });

        ReactPixel.pageView();
      });
  }, [facebookPixelSettings]);

  useEffect(() => {
    if (!facebookPixelSettings?.['pixel-id']) {
      return () => null;
    }

    router.events.on('routeChangeComplete', init);

    return () => {
      router.events.off('routeChangeComplete', init);
    };
  }, [router, init, facebookPixelSettings]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const track = useCallback(
    (event: TTrackEvent, params?: Record<string, any>) => {
      if (!facebookPixelSettings?.['pixel-id']) return;

      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(facebookPixelSettings['pixel-id'], undefined, {
            autoConfig: true,
            debug: false,
          });

          ReactPixel.track(event, params);
        });
    },
    [facebookPixelSettings]
  );

  return (
    <FacebookPixelContext.Provider
      value={{
        track,
      }}
    >
      {children}
    </FacebookPixelContext.Provider>
  );
};

function useFacebookPixel(): IFacebookPixelContextData {
  return useContext(FacebookPixelContext);
}

export { FacebookPixelProvider, useFacebookPixel };
