import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { WithChildren } from 'types/WithChildren';

export const ScrollToTop: WithChildren = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    const scrollToTop = (): void => {
      const mainElement = document.getElementsByTagName('main')?.[0];

      if (mainElement) {
        mainElement.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    };

    router.events.on('routeChangeComplete', scrollToTop);

    return () => {
      router.events.off('routeChangeComplete', scrollToTop);
    };
  }, [router]);

  return <>{children}</>;
};
