import { ReactNode } from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'typeface-montserrat';
import 'utils/yupValidation';

import Head from 'next/head';
import App from 'next/app';
import type { AppProps, AppContext } from 'next/app';

import FloatWhatsapp from '@pra-vendas-themes/default/components/FloatWhatsapp';

import { SessionStorageConstantsEnum } from 'constants/SessionStorageConstantsEnum';

import { SiteSettingsProvider } from 'hooks/useSiteSettings';
import AppProvider from 'hooks';

import { setSessionStorageItem } from 'utils/setSessionStorageItem';
import { getSessionStorageItem } from 'utils/getSessionStorageItem';
import { getDomainByContext } from 'utils/getDomainByContext';

import { getStoreSettings } from 'services/api/storeService';
import { getPromotions } from 'services/api/promotionsService';
import { getPages } from 'services/api/pageService';
import { getNewsletterSettings } from 'services/api/newsletterService';
import { getCategories } from 'services/api/categoryService';
import { getAppConfigurations } from 'services/api/appConfigurationsService';
import { api } from 'services/api';

import { IStoreSettings } from 'types/IStoreSettings';
import { IPromotion } from 'types/IPromotion';
import { IPage } from 'types/IPage';
import { INewsletter } from 'types/INewsletter';
import { ICategory } from 'types/ICategory';
import { IAppConfiguration } from 'types/IAppConfiguration';

import { Layout } from 'components/Layout';

import 'components/TopProgressBar';

interface IMyApp {
  storeSettings: IStoreSettings;
  categories: ICategory[];
  pages: IPage[];
  newsletterSettings: INewsletter;
  hostname: string;
  promotions: IPromotion[];
  appConfigurations: IAppConfiguration[];
}

function MyApp({
  Component,
  pageProps,
  hostname,
  categories,
  storeSettings,
  pages,
  newsletterSettings,
  promotions,
  appConfigurations,
}: AppProps & IMyApp): ReactNode {
  api.defaults.headers['x-domain'] = hostname;

  setSessionStorageItem(
    SessionStorageConstantsEnum.APP_PROPS,
    JSON.stringify({
      hostname,
      categories,
      storeSettings,
      pages,
      newsletterSettings,
      promotions,
      appConfigurations,
    })
  );

  return (
    <SiteSettingsProvider
      storeSettings={storeSettings}
      categories={categories}
      pages={pages}
      newsletterSettings={newsletterSettings}
      promotions={promotions}
      appConfigurations={appConfigurations}
    >
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>

      <AppProvider>
        <Layout>
          <Component {...pageProps} />
          <FloatWhatsapp whatsappNumber={storeSettings?.contact?.whatsapp} />
        </Layout>
      </AppProvider>
    </SiteSettingsProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const hostname = getDomainByContext(appContext.ctx);

  try {
    const cachedAppProps = getSessionStorageItem<AppProps>(
      SessionStorageConstantsEnum.APP_PROPS
    );

    if (cachedAppProps) {
      return {
        hostname,
        ...appProps,
        ...cachedAppProps,
      };
    }

    if (typeof window === 'undefined') {
      const [
        storeSettings,
        categories,
        pages,
        newsletterSettings,
        promotions,
        appConfigurations,
      ] = await Promise.all([
        await getStoreSettings({ hostname }),
        await getCategories({ hostname }),
        await getPages({ hostname }),
        await getNewsletterSettings({ hostname }),
        await getPromotions({ hostname }),
        await getAppConfigurations({ hostname }),
      ]);

      return {
        ...appProps,
        hostname,
        categories,
        storeSettings,
        pages,
        newsletterSettings,
        promotions,
        appConfigurations,
      };
    }
  } catch (error) {
    return {
      ...appProps,
      hostname,
    };
  }
};

export default MyApp;
