export function getSessionStorageItem<T>(key: string): T {
  if (typeof window !== 'undefined') {
    const cached = window.sessionStorage.getItem(key);
    if (cached) {
      const parsed = JSON.parse(cached) as T;
      return parsed;
    }
  }

  return null;
}
