import {
  createContext,
  useContext,
  useCallback,
  useState,
  FunctionComponent,
  useEffect,
} from 'react';

import {
  IModalConfig,
  IModalProps,
} from '@pra-vendas-themes/interfaces/IModal';

import { WithChildren } from 'types/WithChildren';

import { Modal } from 'components/Modal';

interface IModalContextData {
  openModal: (component: FunctionComponent<any>, config?: IModalConfig) => void;
  closeModal: () => void;
}

const ModalContext = createContext<IModalContextData>({} as IModalContextData);

const ModalProvider: WithChildren = ({ children }) => {
  const [modal, setModal] = useState<IModalProps | undefined>(undefined);

  const closeModal = useCallback(() => {
    if (modal) {
      setModal({
        ...modal,
        isClosed: true,
      });
    }
  }, [modal]);

  const openModal = useCallback(
    (component, config?: IModalConfig) => {
      if (modal) {
        closeModal();
        setTimeout(() => {
          setModal({
            component,
            config,
            isClosed: false,
          });
        }, 400);
      } else {
        setModal({
          component,
          config,
          isClosed: false,
        });
      }
    },
    [closeModal, modal]
  );

  useEffect(() => {
    if (modal && modal.isClosed) {
      setTimeout(() => {
        setModal(undefined);
      }, 300);
    }
  }, [modal]);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <Modal modal={modal} handleClose={closeModal} />
      {children}
    </ModalContext.Provider>
  );
};

function useModal(): IModalContextData {
  return useContext(ModalContext);
}

export { ModalProvider, useModal };
