/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { api } from 'services/api';

import { IHostnameParam } from 'types/IHostnameParam';
import type { ICategory } from 'types/ICategory';

export type TGetCategoriesParams = IHostnameParam & {
  showProducts?: string;
};

export async function getCategories(
  config?: TGetCategoriesParams
): Promise<ICategory[]> {
  try {
    const { hostname } = config || {};

    const response = await api.get<ICategory[]>('/categories', {
      params: {
        showSubcategories: true,
        status: 'active',
      },
      ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
    });

    return response.data;
  } catch (error) {
    console.error('[getCategories]', error.message);
    return [];
  }
}

export type TGetCategoryByTagParams = IHostnameParam & {
  tag: string;
};

export async function getCategoryByTag(
  config: TGetCategoryByTagParams
): Promise<ICategory> {
  const { hostname, tag } = config || {};

  const response = await api.get<ICategory>(`/categories/tag/${tag}`, {
    params: {
      status: 'active',
      showSubcategories: true,
      showProducts: true,
    },
    ...(hostname ? { headers: { 'x-domain': hostname } } : {}),
  });

  return response.data;
}
