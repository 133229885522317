import {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';

import { useRouter } from 'next/router';

import { signIn as signInService } from 'services/api/userService';
import { getCustomerById } from 'services/api/customerService';
import { addBearerToken } from 'services/api';

import { WithChildren } from 'types/WithChildren';

interface ISignInCredentials {
  email: string;
  password: string;
}

interface IAddress {
  postalCode: string;
  street: string;
  number: string;
  neighborhood: string;
  complement: string;
  city: string;
  state: string;
}

export interface ICustomer {
  _id: string;
  email: string;
  name: string;
  document: string;
  phoneNumber: string;
  addresses: IAddress[];
}

interface IData {
  customer: ICustomer;
  token: string;
}

interface IAuthContextData {
  customer: ICustomer;
  token: string;
  isLogged: boolean;
  signIn: (
    signInCredentials: ISignInCredentials,
    redirect?: boolean
  ) => Promise<void>;
  signOut: () => void;
  refreshCustomer: (updatedCustomer?: ICustomer) => void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const privateRoutes = /^(\/conta)/;

const AuthProvider: WithChildren = ({ children }) => {
  const router = useRouter();

  const [data, setData] = useState<IData>(() => {
    if (typeof document === 'undefined') return {} as IData;

    const storagedToken = localStorage.getItem('@PraVendas:token');
    const storagedCustomer = JSON.parse(
      localStorage.getItem('@PraVendas:customer') || '{}'
    );

    if (storagedCustomer && storagedToken) {
      addBearerToken(storagedToken);

      return {
        customer: storagedCustomer,
        token: storagedToken,
      };
    }
  });

  const [isLogged, setIsLogged] = useState(() => {
    if (typeof document === 'undefined') return false;

    const storagedIsLogged = localStorage.getItem('@PraVendas:isLogged');

    return !!storagedIsLogged;
  });

  const signIn = useCallback(
    async (signInCredentials: ISignInCredentials, redirect = true) => {
      const response = await signInService(signInCredentials);

      const { token, customer } = response;

      setData({
        customer,
        token,
      });

      addBearerToken(token);

      localStorage.setItem('@PraVendas:token', token);
      localStorage.setItem('@PraVendas:customer', JSON.stringify(customer));
      localStorage.setItem('@PraVendas:isLogged', String(true));

      if (redirect) {
        router.push('/conta');
      }

      setIsLogged(true);

      return customer;
    },
    [router]
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@PraVendas:token');
    localStorage.removeItem('@PraVendas:customer');
    localStorage.removeItem('@PraVendas:isLogged');

    if (privateRoutes.exec(router.asPath)) {
      router.push('/');
    }

    setData({} as IData);
    setIsLogged(false);
  }, [router]);

  const refreshCustomer = useCallback(
    async (updatedCustomer?: ICustomer) => {
      if (updatedCustomer) {
        localStorage.setItem(
          '@PraVendas:customer',
          JSON.stringify(updatedCustomer)
        );

        setData((prev) => ({
          ...prev,
          customer: updatedCustomer,
        }));
      } else {
        const newCustomer = await getCustomerById(data?.customer._id);

        localStorage.setItem(
          '@PraVendas:customer',
          JSON.stringify(newCustomer)
        );
        setData((prev) => ({
          ...prev,
          customer: newCustomer,
        }));
      }
    },
    [data]
  );

  useEffect(() => {
    async function validateToken(): Promise<void> {
      if (data?.customer?._id) {
        try {
          await getCustomerById(data.customer._id);
        } catch (e) {
          signOut();
        }
      }
    }

    validateToken();
  }, [data, signOut]);

  return (
    <AuthContext.Provider
      value={{
        customer: data?.customer,
        token: data?.token,
        isLogged,
        signIn,
        signOut,
        refreshCustomer,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);
  const router = useRouter();

  if (
    privateRoutes.exec(router.asPath) &&
    !context.isLogged &&
    typeof window !== 'undefined'
  ) {
    router.push('/');
  }

  return context;
}

export { AuthProvider, useAuth };
