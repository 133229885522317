import { FC } from 'react';

import { useSiteSettings } from 'hooks/useSiteSettings';
import { useComponentsTheme } from 'hooks/useComponentsTheme';

export const Cookies: FC = () => {
  const { Cookies: CookiesTheme } = useComponentsTheme();

  const { storeSettings } = useSiteSettings();

  const { cookies } = storeSettings || {};

  return <CookiesTheme cookies={cookies} />;
};
