import { IBag } from '@pra-vendas-themes/interfaces/IBag';

import { useComponentsTheme } from 'hooks/useComponentsTheme';

import { WithChildren } from 'types/WithChildren';

export const Bag: WithChildren<IBag> = ({ children, ...props }) => {
  const { Bag: BagTheme } = useComponentsTheme();

  return <BagTheme {...props}>{children}</BagTheme>;
};
