import dynamic from 'next/dynamic';

import { IFormField } from '@pra-vendas-themes/interfaces/IFormField';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const FormFields = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "FormFieldDefault" */ '@pra-vendas-themes/default/components/Form/FormField'
      ),
    {
      ssr: true,
    }
  ),
};

type TUseFormFieldData = {
  FormField: WithChildren<IFormField>;
};

export const useFormFieldTheme = (theme: TThemes): TUseFormFieldData => {
  const FormField = FormFields[theme] as WithChildren<IFormField>;

  return { FormField };
};
