import dynamic from 'next/dynamic';

import { IProductsList } from '@pra-vendas-themes/interfaces/IProductsList';

import { WithChildren } from 'types/WithChildren';
import { TThemes } from 'types/TThemes';

const ProductsLists = {
  default: dynamic(
    () =>
      import(
        /* webpackChunkName: "ProductsListDefault" */ '@pra-vendas-themes/default/components/ProductsList'
      )
  ),
};

type TUseProductsListData = {
  ProductsList: WithChildren<IProductsList>;
};

export const useProductsListTheme = (theme: TThemes): TUseProductsListData => {
  const ProductsList = ProductsLists[theme] as WithChildren<IProductsList>;

  return { ProductsList };
};
