import * as Yup from 'yup';

import { validatePhoneNumber } from 'utils/validatePhoneNumber';
import { validatePrivateDocument } from 'utils/validatePrivateDocument';

Yup.addMethod(Yup.string, 'cpf', function (errorMessage) {
  return this.test(`test-cpf-valid`, errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validatePrivateDocument(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, 'phoneNumber', function (errorMessage) {
  return this.test(`test-phone-number-valid`, errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validatePhoneNumber(value) || createError({ path, message: errorMessage })
    );
  });
});
